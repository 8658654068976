
@font-face {
  font-family:'Readex Pro', sans-serif;
  src: url(../fonts/ReadexPro-Regular.woff2);
}

.bg2 {
background: #171d27;
  background-image: url(), linear-gradient(#1c1b54, #aa31d1);
  height: 200px;
  background-repeat: no-repeat;
  color: aliceblue;
}
.app-bg1 {
  position: relative;
  top: 0;
  right: 24px;
  width: calc(var(--desktop-base-size)*255);
}
.app-bg2 {
  position: absolute;
  right: -10px;
  width: calc(var(--desktop-base-size)*255);
  max-width: 265px;
}
.continer2
{
margin:0 auto;
position:relative;
}
.leftbg
{
display:flex;
width:15%;
}
.midbg
{
display:flex;
width:70%;
}
.rightbg
{
display:flex;
width:15%;
}

.mpad
{
padding:5px 0 0 0;
}

:root {
  --color-one: #e6e6ff;
  --color-two: #1a1aff;
  --color-three: #b3b3ff;
}


.card {
  border: 1px solid #f4f3f3;
  margin-bottom: 20px;
  transition: border 0.1s, transform 0.3s;
}

/* .card .card-body h2 {
  color: var(--color-two);
} */

/* .card img:hover {
opacity: 0.6;
} */

.card-p {
  color: var(--color-three);
}

.card-p i {
  color: var(--color-two);
  margin-right: 8px;
}
.service1 strong
{
color:#062350;
font-size:16px;
}
.service1 p
{
color:gray;
font-size:14px;
}
.service1 span
{
color:#6f2d80;

}
.ser-des strong
{
font-size:14px;
color:#6f2d80;
}
.sub-heading p
{
font-size:16px;
color:#6f2d80;
}
.sub-heading strong
{
color:#062350;
font-size:16px;
}
.footer
{
background-color:#001f4d;
color:#fff;
font-size:14px;
}
.mid-sec
{
background-color: #fff;
width:500px;
border-radius:5px;
}

.mid1-right 
{
padding:0;
margin:0;
}
.mid1-left 
{
padding:0;
margin:0;
}
.mid1-right b
{
font-size:16px;
color:black;
}
.mid1-left,.ml1 a
{
font-size:14px;
color:#a531cd  !important;
}
.mid1-left,.ml1 p
{
font-size:14px;
color:black;
}
.mid-sec-inner span
{
color:red;
}
.mid-sec-inner 
{
padding: 0 0 0 20px;
}
.mid-sec-inner label
{
color:gray;
}
.mid-sec-inner h3
{
color:black;
}

.mid-sec-inner1 
{
padding: 0 0 0 20px;
}
.mid-sec-inner1 label
{
color:gray;
}
.mid-sec-inner1 h3
{
color:black;
}
.minput
{
width:90%;
}


@media screen and (min-width: 2000px) {
.continer2 {
  padding-left: 100px;
}
}

@media screen and (min-width: 2500px) {
.continer2 {
  padding-left: 200px;
}
}
@media screen and (min-width: 3000px) {
.continer2 {
  padding-left: 400px;
}
}
@media screen and (min-width: 3500px) {
.continer2 {
  padding-left: 500px;
}
}
@media screen and (min-width: 4000px) {
.continer2 {
  padding-left: 700px;
}
}
@media screen and (min-width: 4500px) {
.continer2 {
  padding-left: 800px;
}
}
@media screen and (min-width: 5000px) {
.continer2 {
  padding-left: 1000px;
}
}
@media screen and (min-width: 5500px) {
.continer2 {
  padding-left: 1100px;
}
}

